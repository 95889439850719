//import $ from "jquery"
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import AOS from "aos"
import Swiper from 'swiper';
import {Navigation} from "swiper/modules";

import lightbox from "lightbox2"
import "lightbox2/dist/css/lightbox.css"

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { faLinkedin, faTelegramPlane, faSquareFacebook, faSquareInstagram } from "@fortawesome/free-brands-svg-icons"

library.add(faSquareFacebook);
library.add(faLinkedin);
library.add(faTelegramPlane);

dom.watch()

/*
 Alpine
 */
window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.start()

const sections = document.querySelectorAll('.oc-box')
const articles = document.querySelectorAll('.article')
sections.forEach(section => {
    section.dataset.aos = 'fade-up'
    section.dataset.aosEasing = 'ease-out-quad'
    section.dataset.aosDelay = '100'
})
articles.forEach(section => {
    section.dataset.aos = 'fade-up'
    section.dataset.aosEasing = 'ease-out-quad'
    section.dataset.aosDelay = '100'
})

AOS.init({
    once: true
})

let latestNewsSwiper = new Swiper(".latest-news-swiper", {
    modules: [ Navigation ],
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    navigation: {
        nextEl: ".swiper-button-prev",
        prevEl: ".swiper-button-next",
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 50,
        },
    },
});

//
// let swiper3 = new Swiper(".brands", {
//     modules: [ Navigation ],
//     slidesPerView: 7.2,
//     spaceBetween: 10,
//     loop: true,
//     navigation: {
//         nextEl: ".brands.swiper-button-next",
//         prevEl: ".brands.swiper-button-prev",
//     },
// });
//
// let swiper4 = new Swiper(".cats", {
//     modules: [ Navigation ],
//     slidesPerView: 10.2,
//     spaceBetween: 10,
//     loop: true,
//     navigation: {
//         nextEl: ".cats.swiper-button-next",
//         prevEl: ".cats.swiper-button-prev",
//     },
// });
